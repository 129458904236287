import { ComponentType, FunctionComponent, ReactElement, useMemo } from 'react';
import { useHavePermission } from './useHavePermission';
import { Permission } from './types';

type ProtectedComponentProps = {
  children: ReactElement;
  fallbackComponent?: ReactElement;
  permission: Permission | undefined;
};

export const RestrictedComponent = ({ children, fallbackComponent = <></>, permission }: ProtectedComponentProps) => {
  const actionEnabled: boolean | undefined = useHavePermission(permission);
  const restrictedComponentToRender = useMemo(() => {
    if (actionEnabled === undefined) {
      return <></>;
    } else if (actionEnabled) {
      return children;
    } else {
      return fallbackComponent;
    }
  }, [actionEnabled, children, fallbackComponent]);

  return restrictedComponentToRender;
};

export function withRestriction(
  Component: ComponentType,
  permission: Permission,
  fallbackComponent?: ReactElement
): FunctionComponent {
  const componentWithRestriction: FunctionComponent = () => {
    return (
      <RestrictedComponent permission={permission} fallbackComponent={fallbackComponent}>
        <Component />
      </RestrictedComponent>
    );
  };
  componentWithRestriction.displayName = `${Component.displayName}.restricted`;
  return componentWithRestriction;
}
