import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ElementType, FC } from 'react';
import { grayScaleColor, selectedIconColor, selectedTextColor, sidebarBackgroundColor } from '../../../../config/theme/themeSetup';

export interface SideBarItemProps extends ListItemButtonProps {
  active?: boolean;
  component?: ElementType;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title: string;
}

const sideBarItemSx: SxProps<Theme> = {
  color: (theme) => theme.palette.common.white,
  backgroundColor: sidebarBackgroundColor,
  '& .MuiListItemIcon-root': {
    color: selectedTextColor,
  },
  '&$selected': {
    backgroundColor: sidebarBackgroundColor,
    color: selectedTextColor,
    borderColor: (theme) => theme.palette.secondary.main,
    '& .MuiListItemIcon-root': {
      color: (theme) => theme.palette.secondary.main,
    },
  },
  '&$selected:hover': {
    backgroundColor: sidebarBackgroundColor,
    color: selectedTextColor,
    '& .MuiListItemIcon-root': {
      color: (theme) => theme.palette.secondary.main,
    },
  },
  '&:hover': {
    backgroundColor: sidebarBackgroundColor,
    color: selectedTextColor,
    '& .MuiListItemIcon-root': {
      color: selectedTextColor,
    },
  },
};

export const SideBarItem: FC<SideBarItemProps> = ({ active = false, title, Icon, ...otherProps }) => {
  return (
    <ListItemButton
      sx={{
        ...sideBarItemSx,
        backgroundColor: active ? `#ffffff30 !important` : sidebarBackgroundColor,
        pl: (theme) => `calc(${theme.spacing(2)}`,
      }}
      {...otherProps}
    >
      {Icon ? (
        <ListItemIcon>
          {<Icon htmlColor={active ? selectedIconColor : grayScaleColor.iconLight} />}
        </ListItemIcon>
      ) : null}
      <ListItemText
        primary={
          <Typography
            color={active ? 'white' : grayScaleColor.textLight}
            fontSize={'14px'}
            fontWeight={400}
            letterSpacing={0.5}
            noWrap
          >
            {title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};
