import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, styled, Toolbar, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React, { FC } from 'react';
import { Profile } from './Profile';

interface AppBarProps extends MuiAppBarProps {
  maxOffset: number;
  minOffset: number;
  open: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'maxOffset' && prop !== 'minOffset',
})<AppBarProps>(({ maxOffset, minOffset, open, theme }) => ({
  zIndex: theme.zIndex.drawer,
  width: `calc(100% - ${minOffset}px - 1px)`,
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: maxOffset,
    width: `calc(100% - ${maxOffset}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface HeaderProps {
  maxOffset?: number;
  minOffset?: number;
  onClickMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  open?: boolean;
}

export const Header: FC<HeaderProps> = ({ maxOffset = 250, minOffset = 72, open = false, onClickMenu }) => {
  return (
    <AppBar position="absolute" open={open} maxOffset={maxOffset} minOffset={minOffset}>
      <Toolbar
        sx={{
          pr: (theme) => theme.spacing(3),
          backgroundColor: (theme) => theme.palette.common.white,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onClickMenu}
          sx={{
            color: (theme) => theme.palette.primary.dark,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        <Profile />
      </Toolbar>
    </AppBar>
  );
};
