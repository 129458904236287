import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { MouseEvent, useState } from 'react';
import useAuth from '../../auth/useAuth';

export const Profile = () => {
  const { user, authenticated, logout, loading } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    logout();
  };

  return authenticated ? (
    <>
      <Box sx={{ flexGrow: 0, ml: 1.5 }}>
        <Button
          variant="text"
          color="inherit"
          sx={{ p: 0, textDecoration: 'none !important' }}
          onClick={handleOpenMenu}
        >
          <Typography fontSize={15} sx={{ color: (theme) => theme.palette.primary.dark, mr: 1.5 }}>
            {loading ? <Skeleton /> : user?.name}
          </Typography>
          {loading ? (
            <Skeleton>
              <Avatar />
            </Skeleton>
          ) : (
            <Avatar alt={user?.name} src={user?.picture} />
          )}
        </Button>
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenu}
        PaperProps={{ sx: { mt: 7, width: 234 } }}
      >
        <MenuItem onClick={handleLogout}>Salir</MenuItem>
      </Menu>
    </>
  ) : (
    <></>
  );
};
