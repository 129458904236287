import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { StockyLogoIcon, StockyTextIcon } from '../../Icons/Stocky';
import { BASE_PREFIX_PATH } from '../../../config/routes';
import { Link } from 'react-router-dom';
import { sidebarBackgroundColor } from '../../../config/theme/themeSetup';

interface DrawerProps extends MuiDrawerProps {
  closeWidth: number;
  openWidth: number;
}

const openedMixin = (theme: Theme, openWidth: number): CSSObject => ({
  backgroundColor: sidebarBackgroundColor,
  width: openWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, closeWidth: number): CSSObject => ({
  backgroundColor: sidebarBackgroundColor,
  width: `calc(${closeWidth}px + 1px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'closeWidth' && prop !== 'open' && prop !== 'openWidth',
})<DrawerProps>(({ closeWidth, open, openWidth, theme }) => ({
  width: openWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, openWidth),
    '& .MuiDrawer-paper': openedMixin(theme, openWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, closeWidth),
    '& .MuiDrawer-paper': closedMixin(theme, closeWidth),
  }),
}));

/*
const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 0),
  backgroundColor: theme.palette.primary.dark,
  ...theme.mixins.toolbar,
}));
*/


type SideBarHeaderLogoProps = {
  open: boolean;
}

export const SideBarHeaderLogo: FC<SideBarHeaderLogoProps> = ({ open }) => {
  return (
    <Link to={`${BASE_PREFIX_PATH}/dashboard`} >
      <ListItem sx={{ py: 0 }}>
        {
          open
            ? <ListItemText>
              <StockyTextIcon />
            </ListItemText>
            : <ListItemIcon sx={{ ml: '-10px' }}>
              <StockyLogoIcon />
            </ListItemIcon>
        }
      </ListItem>
    </Link>
  );
};

interface SideBarProps {
  children: ReactNode;
  closeWidth?: number;
  open?: boolean;
  openWidth?: number;
}

export const SideBar: FC<SideBarProps> = ({ children, closeWidth = 72, open = true, openWidth = 200 }) => {
  return (
    <Drawer variant="permanent" closeWidth={closeWidth} open={open} openWidth={openWidth}>
      <List sx={{ backgroundColor: sidebarBackgroundColor, fontFamily: 'Gotham Narrow' }}>
        {children}
      </List>
    </Drawer>
  );
};
