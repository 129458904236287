import { createTheme } from '@mui/material/styles';

export const grayScaleColor = {
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0,0,0,1)',
  light: 'rgba(228, 228, 228, 1)', //#E4E4E4
  normal: 'rgba(174, 175, 175, 1)', //#AEAFAF
  normalDark: 'rgba(102, 102, 102, 1)', //#7C7D80
  dark: 'rgba(40,41,43,1)', //#28292B
  darker: '#7c7c7c',
  textLight: 'rgba(196, 196, 196,1)',
  iconLight: 'rgba(196, 196, 196,1)',
};

export const blueColor = {
  light: 'rgba(78, 127, 220, 1)',
  normal: 'rgba(34, 96, 212, 1)',
  dark: 'rgba(17, 48, 105, 1)',
  darker: 'rgba(14, 36, 80, 1)',
  textDark: 'rgba(11, 30, 67, 1)',
  textDarker: 'rgba(7, 25, 52, 1)',
};

export const maronColor = {
  light: '#A63337',
  normal: '#900105',
  dark: '#500608',
  darker: '#270001',
};

export const redColorB = {
  light: '#de6969',
  normal: '#d60000',
  dark: '#9c0000',
  darker: '#6d0000',
};

export const successGreenColor = {
  light: 'rgba(102, 204, 102, 0.1)',
  normal: 'rgba(102, 204, 102, 1)',
};

export const textLinkGreyColor = {
  light: 'rgba(228, 228, 228,1)',
  normal: 'rgba(104, 105, 106,1)',
  dark: 'rgba(40, 41, 43,1)',
};

const BUTTON_SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const buttonSizeStyles = {
  [BUTTON_SIZES.LARGE]: { height: '48px', fontSize: '15px' },
  [BUTTON_SIZES.MEDIUM]: { height: '40px', fontSize: '14px' },
  [BUTTON_SIZES.SMALL]: { height: '32px', fontSize: '13px' },
};

export const sidebarBackgroundColor = maronColor.darker;
export const selectedIconColor = 'white';
export const selectedTextColor = maronColor.normal;

const themeSetup = createTheme({
  palette: {
    common: { black: 'rgba(40, 41, 43, 1)', white: grayScaleColor.white },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      light: grayScaleColor.normal,
      main: 'rgba(80, 81, 84, 1)',
      dark: 'rgba(40, 41, 43, 1)',
      contrastText: '#fff',
    },
    secondary: {
      main: maronColor.normal,
      light: maronColor.light,
      dark: maronColor.dark,
      contrastText: '#fff',
    },
    error: {
      main: '#F23030',
    },
    warning: {
      main: '#F99F1B',
    },
    info: {
      light: grayScaleColor.light,
      main: '#1675CE',
    },
    success: {
      main: '#00D689',
    },
    text: {
      primary: 'rgba(40, 41, 43, 1)', // #28292b
      secondary: 'rgba(40, 41, 43, 0.54)',
      disabled: 'rgba(40, 41, 43, 0.38)',
    },
    action: {
      disabled: grayScaleColor.light,
      disabledBackground: 'rgba(124, 125, 128, 1)',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer-maxWidthLg': {
            maxWidth: '1262px',
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
      styleOverrides: {
        indicator: {
          height: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: grayScaleColor.black,
          fontFamily: 'Roboto Medium',
          fontSize: 18,
          fontWeight: 500,
          textTransform: 'unset',
          padding: '8px 8px 10px 8px',
          '&.Mui-selected': {
            color: grayScaleColor.black,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: '500',
          fontFamily: 'roboto medium',
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          minWidth: 50,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: 60,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fontSize: '24px',
          color: `${grayScaleColor.dark}`,
        },
        root: {
          '&:hover, &:active, &:focus': {
            color: `${grayScaleColor.dark}`,
          },
          '&.orderingBy .MuiSvgIcon-root': {
            opacity: 1,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          border: 'solid 1px #e4e4e4',
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 10,
          paddingRight: '39px',
          textAlign: 'left',
        },
        toolbar: {
          zIndex: 10,
          overflow: 'hidden',
          height: 64,
          paddingRight: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderColor: grayScaleColor.light,
          borderWidth: '1px',
          borderStyle: 'solid',
          '&.Mui-disabled': { backgroundColor: grayScaleColor.white },
          "&:before": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderColor: grayScaleColor.light,
          borderWidth: '1px',
          borderStyle: 'solid',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '::-webkit-input-placeholder': {
            opacity: 1,
            color: grayScaleColor.normal,
            textOverflow: 'ellipsis',
            fontSize: '15px',
          },
          height: '20px',
          minHeight: 'auto',
          textOverflow: 'ellipsis',
        },
        root: {
          backgroundColor: grayScaleColor.white,
          '&.Mui-disabled': {
            backgroundColor: '#f6f6f6',
            webkitTextFillColor: grayScaleColor.normal,
          },
        },
        inputMultiline: {
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '45px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          whiteSpace: 'nowrap',
          fontWeight: 700,
          fontFamily: 'Roboto, Open Sans, sans-serif',
          boxShadow: 'none!important',
          paddingLeft: '19px',
          paddingRight: '19px',
          ...buttonSizeStyles[ownerState?.size || BUTTON_SIZES.MEDIUM],
        }),
        text: {
          background: 'none!important',
          textTransform: 'none',
          fontWeight: 'normal',
          textDecoration: 'underline!important',
          color: grayScaleColor.normalDark,
          '&:hover, &:active': {
            color: grayScaleColor.dark,
          },
          '&.Mui-disabled': {
            color: grayScaleColor.light,
          },
        },
        contained: {
          background: maronColor.normal,
          '&:hover': {
            background: maronColor.dark,
          },
          '&:active': {
            background: maronColor.darker,
          },
          color: grayScaleColor.white,
          '&.Mui-disabled': {
            background: grayScaleColor.light,
            color: grayScaleColor.white,
          },
        },
        containedSecondary: {
          background: grayScaleColor.white,
          border: 'solid 1px rgb(238, 238, 238)',
          color: grayScaleColor.dark,
          '&:hover': {
            background: grayScaleColor.white,
            border: 'solid 1px rgb(40, 41, 43)',
          },
          '&:active': {
            background: grayScaleColor.light,
            border: `solid 1px ${grayScaleColor.dark}`,
            color: grayScaleColor.dark,
          },
          '&.Mui-disabled': {
            background: grayScaleColor.white,
            border: `solid 1px ${grayScaleColor.light}`,
            color: grayScaleColor.light,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: { paddingTop: '7px', paddingBottom: '6.5px' },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: grayScaleColor.normal,
        },
        select: {
          fontSize: '14px',
          fontWeight: '360',
          fontFamily: 'Roboto, Open Sans, sans-serif',
          minHeight: 'auto',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: grayScaleColor.normal,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: '360',
          '&.Mui-selected': {
            fontSize: '14px',
            fontWeight: '360',
            fontFamily: 'Roboto, Open Sans, sans-serif',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: '14px',
          paddingBottom: '14px',
          height: '20px',
          minHeight: 'auto',
        },
        notchedOutline: {
          borderColor: grayScaleColor.light,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
        }
      `,
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          margin: 'unset',
          alignSelf: 'center',
        },
        title: {
          fontSize: 'unset',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          paddingLeft: 40,
          paddingRight: 40,
          paddingBottom: 40,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 32,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 28,
          paddingBottom: 28,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          marginBottom: '8px !important',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: grayScaleColor.normal,
            textDecorationColor: grayScaleColor.normal,
            '&:hover': {
              color: grayScaleColor.dark,
              textDecorationColor: grayScaleColor.dark,
            },
            '&:active': {
              color: grayScaleColor.dark,
              textDecorationColor: grayScaleColor.dark,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: blueColor.normal,
            cursor: 'pointer',
            textDecoration: 'underline',
            textDecorationCOLOR: blueColor.normal,
            fontSize: '14px',
            '&:hover': {
              color: blueColor.textDark,
              textDecorationColor: blueColor.textDark,
            },
            '&:active': {
              color: blueColor.textDarker,
              textDecorationColor: blueColor.textDarker,
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: 'Roboto, Open Sans, sans-serif',
  },
});

export default themeSetup;
