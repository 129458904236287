import React from 'react';
import { Navigate } from 'react-router-dom';
import Error404 from '../components/ErrorPage/Error404';
import { buildPath } from '../utils/TextUtils';
import { IS_OPINIONS_ENABLED } from './toggles';

export const BASE_PREFIX_PATH = process.env.REACT_APP_BASE_PREFIX_PATH || '';
export const DASHBOARD_PATH_TOKEN = 'home';
export const MAIN_PAGE = `${BASE_PREFIX_PATH}/${DASHBOARD_PATH_TOKEN}`;
const PLAN_PATH_TOKEN = 'mis-planes';
const OPINIONS_PATH_TOKEN = 'opiniones';
const CREATE_PLAN_PATH_TOKEN = 'creador-de-planes';
const RESOURCES_PATH_TOKEN = `resources`;

export const PLAN_PAGE_PATH = buildPath(BASE_PREFIX_PATH, PLAN_PATH_TOKEN);
export const OPINIONS_PAGE_PATH = buildPath(BASE_PREFIX_PATH, OPINIONS_PATH_TOKEN);
export const OPINIONS_NEW_PAGE_PATH = buildPath(OPINIONS_PAGE_PATH, 'crear');
export const PLAN_CREATOR_PAGE_PATH = buildPath(BASE_PREFIX_PATH, CREATE_PLAN_PATH_TOKEN);
export const RESOURCES_PAGE_PATH = buildPath(BASE_PREFIX_PATH, RESOURCES_PATH_TOKEN);

const routes = {
  [BASE_PREFIX_PATH]: {
    Component: () => <Navigate to={MAIN_PAGE} replace />,
  },
  [`${BASE_PREFIX_PATH}/`]: {
    Component: () => <Navigate to={MAIN_PAGE} replace />,
  },
  [MAIN_PAGE]: {
    Component: React.lazy(() => import('../pages/dashboard/DashboardPage')),
  },
  ...(IS_OPINIONS_ENABLED ? {
    [OPINIONS_PAGE_PATH]: {
      Component: React.lazy(() => import('../pages/opinions/OpinionsPage')),
    },
    [OPINIONS_NEW_PAGE_PATH]: {
      Component: React.lazy(() => import('../pages/opinions/new/NewOpinionPage')),
    },
  } : {}),
  [PLAN_PAGE_PATH]: {
    Component: React.lazy(() => import('../pages/plan/PlanPage')),
  },
  [PLAN_CREATOR_PAGE_PATH]: {
    Component: React.lazy(() => import('../pages/plan/creator/PlanCreatorPage')),
  },
  [RESOURCES_PAGE_PATH]: {
    Component: React.lazy(() => import('../pages/resources/ResourcesPage')),
  },
  [buildPath(BASE_PREFIX_PATH, 'error-404')]: {
    Component: Error404,
  },
  [buildPath(BASE_PREFIX_PATH, '*')]: {
    Component: Error404,
  },
};

export default routes;
