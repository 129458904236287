import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Header } from './Header';
import Page from './Page';
import { SideBar } from './Sidebar';
import { SideBarItem, SideBarItemProps } from './Sidebar/components/SideBarItem';
import { DASHBOARD_PATH_TOKEN, MAIN_PAGE, OPINIONS_PAGE_PATH, PLAN_CREATOR_PAGE_PATH, PLAN_PAGE_PATH } from '../../config/routes';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RestrictedComponent } from '../../auth/RestrictedComponent';
import {
  DASHBOARD_PERMISSIONS,
} from '../../auth/permissionsConfig';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import { IS_OPINIONS_ENABLED } from '../../config/toggles';

const SIDE_BAR_MAX = 220;
const SIDE_BAR_MIN = 58;

interface SideBarItemLinkProps extends SideBarItemProps {
  to: string;
}

const SideBarItemLink: FC<SideBarItemLinkProps> = ({ ...props }) => (
  <SideBarItem {...props} component={RouterLink} />
);

export interface Route {
  Component: FC;
}

interface LayoutProps {
  routes: { [key: string]: Route };
}

const Layout: FC<LayoutProps> = ({ routes }) => {
  const [openSideBar, setOpenSideBar] = useState(false);

  const location = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (matches) {
      setOpenSideBar(false);
    }
  }, [matches]);

  const handleSideBarToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  const isActive = (path: string) => location.pathname === path || location.pathname.includes(path);

  return (
    <Box display="flex" width="100%">
      <Header maxOffset={SIDE_BAR_MAX} minOffset={SIDE_BAR_MIN} open={openSideBar} onClickMenu={handleSideBarToggle} />
      <SideBar closeWidth={SIDE_BAR_MIN} open={openSideBar} openWidth={SIDE_BAR_MAX}>
        <RestrictedComponent permission={DASHBOARD_PERMISSIONS.LIST}>
          <SideBarItemLink
            title={'Home'}
            Icon={HomeIcon}
            to={MAIN_PAGE}
            active={isActive(DASHBOARD_PATH_TOKEN)}
          />
        </RestrictedComponent>
        <SideBarItemLink
          title={'Mis planes'}
          Icon={SchoolIcon}
          to={PLAN_PAGE_PATH}
          active={isActive(PLAN_PAGE_PATH)}
        />
        {
          IS_OPINIONS_ENABLED && <SideBarItemLink
            title={'Opiniones'}
            Icon={ThumbsUpDownIcon}
            to={OPINIONS_PAGE_PATH}
            active={isActive(OPINIONS_PAGE_PATH)}
          />
        }
        <SideBarItemLink
          title={'Creador de planes'}
          Icon={EditIcon}
          to={PLAN_CREATOR_PAGE_PATH}
          active={isActive(PLAN_CREATOR_PAGE_PATH)}
        />
        {/*
        <SideBarItemLink
          title={'Recursos'}
          Icon={MenuBookIcon}
          to={RESOURCES_PAGE_PATH}
          active={isActive(RESOURCES_PAGE_PATH)}
        />
        */}
      </SideBar>
      <Box
        component="main"
        bgcolor={theme.palette.background.default}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        pr={'16px'}
        pl={'16px'}
        overflow="auto"
        mt={8}
      >
        <Page routes={routes} />
      </Box>
    </Box>
  );
};

export default Layout;
