import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './auth/AuthProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import themeSetup from './config/theme/themeSetup';
import { SnackbarProvider } from 'notistack';
import { UniversityProvider } from './state/UniversityContext';
import { Components, DEFAULT_TIMEOUT } from './hooks/useNotifier';

const { version } = require('../package.json');

console.log(version);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={DEFAULT_TIMEOUT}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        Components={Components}>
        <AuthProvider>
          <ThemeProvider theme={themeSetup}>
            <CssBaseline />
            <UniversityProvider>
              <App />
            </UniversityProvider>
          </ThemeProvider>
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
