import React, { createContext, FC, ReactNode, useEffect, useState } from "react";
import { CustomLoader } from "../components/Loader/CustomLoader";
import { University } from "../types/University";

export type UniversityContextProps = {
    getUniversity: () => University;
}

export const UniversityContext = createContext<UniversityContextProps | undefined>(undefined);

export function useUniversityContext(): UniversityContextProps {
    const context = React.useContext(UniversityContext);
    if (context === undefined) {
        throw new Error('useUniversityContext must be used within a UniversityProvider');
    }

    return context;
}

export const UniversityProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [university, setUniversity] = useState<University>();

    useEffect(() => {
        setUniversity({
            id: 1,
            name: 'Universidad Tecnológica Nacional'
        });
    }, []);

    const getUniversity = (): University => {
        if (university === undefined) throw new Error('University is not defined');
        return university;
    }


    return <UniversityContext.Provider value={{ getUniversity }}>
        {
            university === undefined && <CustomLoader fullPage show={true} />
        }
        {
            university !== undefined && children
        }
    </UniversityContext.Provider>
}