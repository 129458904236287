import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './auth/ProtectedRoute';
import Layout from './components/layout/Layout';
import routes, { MAIN_PAGE } from './config/routes';

function App() {
  return (
    <Routes>
      <Route path="*" element={<ProtectedRoute component={() => <Layout routes={routes} />} />} />
      <Route
        path="/"
        element={
          <ProtectedRoute component={() => <Navigate to={MAIN_PAGE} />} />
        } />
    </Routes>
  );
}

export default App;