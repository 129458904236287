import { ErrorPage } from './index';
import {  MAIN_PAGE } from '../../config/routes';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate(MAIN_PAGE);
  };
  return <ErrorPage buttonHandleClick={goToDashboard} errorMessage='Página no encontrada' buttonText='Volver al inicio' />;
};

export default Error404;
