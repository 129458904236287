export const upperCaseFirstCharOnly = (text: string): string =>
  `${text.slice(0, 1).toUpperCase()}${text.slice(1).toLowerCase()}`;

export const isEmpty = (str: string | null | undefined): boolean => !str || str.trim().length === 0;

export const snakeCaseToCamelCase = (str: string) => str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const removeWhiteSpaces = (str: string) => {
  return str.replace(/\s+/g, ' ').trim();
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const getPasswordString = (count: number = 15) => {
  let passwordString = '';
  for (let i = 0; i < count; i++) {
    passwordString = passwordString + '*';
  }
  return passwordString;
};

export const EMAIL_REGEX = new RegExp(
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,}))$'
);

export const buildPath = (...tokens: string[]): string => tokens.join('/');
