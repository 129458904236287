import { Permission, Role } from './types';

type PermissionsConfig = { [key in Role]: Permission[] };

type Key = 'LIST' | 'EDIT' | 'CREATE';

type Permissions = Record<Key, Permission>;

export const DASHBOARD_PERMISSIONS: Omit<Permissions, 'EDIT' | 'CREATE'> = {
  LIST: 'dashboard.list',
};

export const PRODUCTS_PERMISSIONS: Omit<Permissions, 'EDIT' | 'CREATE'> = {
  LIST: 'products.list',
};

export const permissionsConfig: PermissionsConfig = {
  owner: [
    ...Object.values(DASHBOARD_PERMISSIONS),
    PRODUCTS_PERMISSIONS.LIST,
  ],
  member: [
    ...Object.values(DASHBOARD_PERMISSIONS),
    PRODUCTS_PERMISSIONS.LIST,
  ],
};
