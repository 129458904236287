import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

type errorPageProps = {
  errorNumber?: number;
  errorMessage: string;
} & (
    | {
      buttonText: string;
      buttonHandleClick: (event: React.MouseEvent) => void;
    }
    | {
      buttonText?: undefined;
      buttonHandleClick?: undefined;
    }
  );

export const ErrorPage = ({ errorNumber = 404, errorMessage, buttonHandleClick, buttonText }: errorPageProps) => {

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <Typography
        sx={{
          fontFamily: 'Roboto Medium',
          fontSize: '160px',
          fontWeight: 500,
          fontStretch: 'normal',
          fontStyle: 'normal',
          letterSpacing: 'normal',
          textAlign: 'center',
          backgroundColor: (theme) => theme.palette.secondary.main,
          color: 'transparent',
          WebkitBackgroundClip: 'text',
          mozBackgroundClip: 'text',
          height: '220px',
        }}
      >
        {errorNumber}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Roboto',
          fontSize: '20px',
          color: 'rgb(51, 51, 51)',
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          whiteSpace: 'pre-line',
          textAlign: 'center',
        }}
      >
        {errorMessage}
      </Typography>
      {buttonHandleClick && buttonText && (
        <Button variant={'contained'} onClick={buttonHandleClick} size={'large'} sx={{ marginTop: '28px' }}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};
