import { Box, CircularProgress, SxProps } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

interface CustomLoaderProps {
  show?: boolean;
  fullPage?: boolean;
  sx?: SxProps;
  children?: JSX.Element;
}

export const CustomLoader: FC<CustomLoaderProps> = ({ show = true, fullPage = false, sx, children = null }) => {
  if (!show) return children;

  return (
    <Box
      sx={{
        position: fullPage ? 'absolute' : 'relative',
        height: '100%',
        width: '100%',
        display: 'flex',
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: 'rgba(255,255,255,0.5)',
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
};
