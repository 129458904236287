import React, { FC, Suspense } from 'react';
import { Route as RouterRoute, Routes } from 'react-router-dom';
import { Route } from './Layout';
import { CustomLoader } from '../Loader/CustomLoader';
import { Container } from '@mui/material';

interface PageProps {
  routes: { [key: string]: Route };
}

const PageContainer: FC<PageProps> = ({ routes }) => {
  return (
    <Routes>
      {Object.entries(routes).map(
        ([path, route]) =>
          route.Component && (
            <RouterRoute
              key={path}
              path={path}
              element={
                <Suspense fallback={<CustomLoader />}>
                  <Container maxWidth="lg" sx={{ mt: 4, flex: 1 }}>
                    <route.Component />
                  </Container>
                </Suspense>
              }
            />
          )
      )}
    </Routes>
  );
};

export default PageContainer;
